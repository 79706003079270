const MAX_DISTANCE_KEY = 'max-distance';

import { POSTCODE_KEY, REGION_KEY } from '@core/Constants/Filters/LocationFilter.constants';

const OPTIONS = [
  {
    id: POSTCODE_KEY,
    label: 'Code postal',
    value: POSTCODE_KEY,
  },
  {
    id: REGION_KEY,
    label: 'Région',
    value: REGION_KEY,
  },
];

const DISTANCE_STEP = 5;

const MAX_DISTANCE = {
  uk: 100,
  fr: 500,
};

const DEFAULT_DISTANCE = {
  uk: 60,
  fr: 60,
};

export { OPTIONS, MAX_DISTANCE, DEFAULT_DISTANCE, DISTANCE_STEP, MAX_DISTANCE_KEY };
